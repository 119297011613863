
import { defineComponent, toRefs, ref, computed, reactive, watch, Ref, nextTick } from 'vue'
import listLayout from '@/components/listLayout/listLayout.vue'
import FormItem from '@/components/formItem/index.vue'
import { Proposal, RegProposal } from '@/model/industryManagement/proposal'
import editButton from '@/components/editButton/index.vue'
import proposalApi from '@/axios/api/proposal'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import yTable from '@/components/yTable/index.vue'
import { deleteModal } from '@/utils/deleteModal'
import Data from '@/model/common/Data'
import AchieveApi from '@/axios/api/achievementApi'
import productApi from '@/axios/api/product'
import homeApi from '@/axios/api/index'
import customerSelect from '@/components/customerSelect/index.vue'
import { debounce } from 'lodash-es'
import dayjs, { Dayjs } from 'dayjs'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import { TabItem, FormItemListType } from '@/types/comm'
import Drawer from '@/components/Drawer/Drawer.vue'
import { ColumnsTypeData } from '@/components/Drawer/Drawer'
import { DemandLogInfo } from '@/model/industryManagement/proposal'
interface FormRef {
  validateFields: () => Promise<unknown>
  resetFields: () => void
}
export default defineComponent({
  components: {
    listLayout,
    FormItem,
    editButton,
    yTable,
    customerSelect,
    Drawer,
  },
  setup() {
    /**
     * @description: 获取筛选后可展示表头列表
     * @param { Array<ColumnsTypeData> } data 当前所有表头列表
     * @return {*}
     */
    const updateColumns = (value: Array<ColumnsTypeData>) => {
      pageData.columnsListObj[pageData.selectFrom.data.status ?? 'all'] = value
    }
    //获取路由
    const productOptionList = ref([] as Data[])
    //获取store
    const store = useStore()
    const formRef: Ref<FormRef[]> = ref([])
    const buttonObj = store.getters.getButtonObj
    const proposalTypeStatusObj = {
      null: '/',
      1: '创新型',
      2: '拓宽型',
      3: '延伸型',
      4: 'A1',
      5: 'A2',
      6: 'B1',
      7: 'B2',
      8: 'B3',
    }
    const proposalTypeStatusList = [
      {
        value: null,
        label: '/',
      },
      {
        value: 4,
        label: 'A1',
      },
      {
        value: 5,
        label: 'A2',
      },
      {
        value: 6,
        label: 'B1',
      },
      {
        value: 7,
        label: 'B2',
      },
      {
        value: 8,
        label: 'B3',
      },
    ]
    const pageData = reactive({
      isDrawer: true, //是否销毁列设置
      isShowDrawer: false, //列设置弹框展示控制
      historyType: false, //历史弹窗
      historyInfo: [] as DemandLogInfo[],
      selectFrom: new RegProposal(),
      dataSource: [] as Proposal[],
      difference: 0,
      statusObj: {
        0: '待处理',
        1: '评估中',
        2: '开发中',
        3: '报价中',
        4: '已完成',
        5: '已终止',
      } as Data,
      selectedRowKeys: [] as string[],
      primaryIndustry: [],
      options: [] as Data[],
      tabList: [
        { key: null, title: '所有' },
        { key: 0, title: '待分配' },
        { key: 1, title: '评估中' },
        { key: 2, title: '开发中' },
        { key: 3, title: '报价中' },
        { key: 4, title: '已完成' },
        { key: 5, title: '已终止' },
      ] as TabItem[],
      columnsListObj: {
        all: [
          {
            title: '需求编号',
            dataIndex: 'demandNo',
            key: 'demandNo',
            width: 150,
            resizable: true,
          },
          {
            title: '公司名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 200,
            resizable: true,
          },
          {
            title: '业务员',
            dataIndex: 'salesman',
            key: 'salesman',
            width: 120,
            resizable: true,
          },
          {
            title: '联系人',
            dataIndex: 'contact',
            key: 'contact',
            width: 120,
            resizable: true,
          },
          {
            title: '所属行业',
            dataIndex: 'primaryIndustry',
            key: 'primaryIndustry',
            width: 150,
            resizable: true,
          },
          {
            title: '需求产品',
            dataIndex: 'demandProduct',
            key: 'demandProduct',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '设备名称',
            dataIndex: 'deviceName',
            key: 'deviceName',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '需求数量',
            dataIndex: 'demandCount',
            key: 'demandCount',
            width: 100,
            resizable: true,
          },
          {
            title: '提交时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
            width: 150,
            resizable: true,
          },
          {
            title: '提案类型',
            dataIndex: 'proposalType',
            key: 'proposalType',
            width: 120,
            statusObj: proposalTypeStatusObj,
            resizable: true,
          },
          {
            title: '转提案人',
            dataIndex: 'createdBy',
            key: 'createdBy',
            width: 140,
            resizable: true,
          },
          {
            title: '当前状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            resizable: true,
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width: 230,
            fixed: 'right',
            resizable: true,
          },
        ],
        0: [
          {
            title: '需求编号',
            dataIndex: 'demandNo',
            key: 'demandNo',
            width: 150,
            resizable: true,
          },
          {
            title: '公司名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 200,
            resizable: true,
          },
          {
            title: '业务员',
            dataIndex: 'salesman',
            key: 'salesman',
            width: 120,
            resizable: true,
          },
          {
            title: '所属行业',
            dataIndex: 'primaryIndustry',
            key: 'primaryIndustry',
            width: 150,
            resizable: true,
          },
          {
            title: '转提案人',
            dataIndex: 'createdBy',
            key: 'createdBy',
            width: 140,
            resizable: true,
          },
          {
            title: '需求产品',
            dataIndex: 'demandProduct',
            key: 'demandProduct',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '设备名称',
            dataIndex: 'deviceName',
            key: 'deviceName',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '需求数量',
            dataIndex: 'demandCount',
            key: 'demandCount',
            width: 100,
            resizable: true,
          },
          {
            title: '提交时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
            width: 150,
            resizable: true,
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width: 230,
            fixed: 'right',
            resizable: true,
          },
        ],
        1: [
          {
            title: '需求编号',
            dataIndex: 'demandNo',
            key: 'demandNo',
            width: 150,
            resizable: true,
          },
          {
            title: '公司名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 200,
            resizable: true,
          },
          {
            title: '业务员',
            dataIndex: 'salesman',
            key: 'salesman',
            width: 120,
            resizable: true,
          },
          {
            title: '所属行业',
            dataIndex: 'primaryIndustry',
            key: 'primaryIndustry',
            width: 120,
            resizable: true,
          },
          {
            title: '需求产品',
            dataIndex: 'demandProduct',
            key: 'demandProduct',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '设备名称',
            dataIndex: 'deviceName',
            key: 'deviceName',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '转提案人',
            dataIndex: 'createdBy',
            key: 'createdBy',
            width: 140,
            resizable: true,
          },
          {
            title: '需求数量',
            dataIndex: 'demandCount',
            key: 'demandCount',
            width: 100,
            resizable: true,
          },
          {
            title: '产品部门',
            dataIndex: 'productDept',
            key: 'productDept',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '产品经理',
            dataIndex: 'productManager',
            key: 'productManager',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '分配人',
            dataIndex: 'assignee',
            key: 'assignee',
            width: 100,
            resizable: true,
          },
          {
            title: '提案类型',
            dataIndex: 'proposalType',
            key: 'proposalType',
            width: 120,
            statusObj: proposalTypeStatusObj,
            resizable: true,
          },
          {
            title: '客户需求时间',
            dataIndex: 'demandDate',
            key: 'demandDate',
            width: 150,
            resizable: true,
          },
          {
            title: '期望完成时间',
            dataIndex: 'expectedTime',
            key: 'expectedTime',
            width: 130,
            resizable: true,
          },
          {
            title: '评估状态停留时间',
            dataIndex: 'assessDwellTime',
            key: 'assessDwellTime',
            width: 130,
            resizable: true,
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width: 230,
            fixed: 'right',
            resizable: true,
          },
        ],
        2: [
          {
            title: '需求编号',
            dataIndex: 'demandNo',
            key: 'demandNo',
            width: 150,
            resizable: true,
          },
          {
            title: '公司名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 200,
            resizable: true,
          },
          {
            title: '业务员',
            dataIndex: 'salesman',
            key: 'salesman',
            width: 120,
            resizable: true,
          },
          {
            title: '所属行业',
            dataIndex: 'primaryIndustry',
            key: 'primaryIndustry',
            width: 150,
            resizable: true,
          },
          {
            title: '需求产品',
            dataIndex: 'demandProduct',
            key: 'demandProduct',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '转提案人',
            dataIndex: 'createdBy',
            key: 'createdBy',
            width: 140,
            resizable: true,
          },
          {
            title: '设备名称',
            dataIndex: 'deviceName',
            key: 'deviceName',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '需求数量',
            dataIndex: 'demandCount',
            key: 'demandCount',
            width: 100,
            resizable: true,
          },
          {
            title: '分配人',
            dataIndex: 'assignee',
            key: 'assignee',
            width: 100,
            resizable: true,
          },
          {
            title: '开发部门',
            dataIndex: 'productDept',
            key: 'productDept',
            width: 130,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '产品经理',
            dataIndex: 'productManager',
            key: 'productManager',
            width: 130,
            resizable: true,
          },
          {
            title: '提案类型',
            dataIndex: 'proposalType',
            key: 'proposalType',
            width: 120,
            statusObj: proposalTypeStatusObj,
            resizable: true,
          },
          {
            title: '提交时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
            width: 150,
            resizable: true,
          },
          {
            title: '评估时间',
            dataIndex: 'assessorDate',
            key: 'assessorDate',
            width: 120,
            resizable: true,
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width: 230,
            fixed: 'right',
            resizable: true,
          },
        ],
        3: [
          {
            title: '需求编号',
            dataIndex: 'demandNo',
            key: 'demandNo',
            width: 150,
            resizable: true,
          },
          {
            title: '公司名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 200,
            resizable: true,
          },
          {
            title: '业务员',
            dataIndex: 'salesman',
            key: 'salesman',
            width: 120,
            resizable: true,
          },
          {
            title: '所属行业',
            dataIndex: 'primaryIndustry',
            key: 'primaryIndustry',
            width: 150,
            resizable: true,
          },
          {
            title: '需求产品',
            dataIndex: 'demandProduct',
            key: 'demandProduct',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '设备名称',
            dataIndex: 'deviceName',
            key: 'deviceName',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '需求数量',
            dataIndex: 'demandCount',
            key: 'demandCount',
            width: 100,
            resizable: true,
          },
          {
            title: '转提案人',
            dataIndex: 'createdBy',
            key: 'createdBy',
            width: 140,
            resizable: true,
          },
          {
            title: '分配人',
            dataIndex: 'assignee',
            key: 'assignee',
            width: 100,
            resizable: true,
          },
          {
            title: '开发部门',
            dataIndex: 'productDept',
            key: 'productDept',
            width: 130,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '产品经理',
            dataIndex: 'productManager',
            key: 'productManager',
            width: 130,
            resizable: true,
          },
          {
            title: '提案类型',
            dataIndex: 'proposalType',
            key: 'proposalType',
            width: 120,
            statusObj: proposalTypeStatusObj,
            resizable: true,
          },
          {
            title: '提交时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
            width: 150,
            resizable: true,
          },
          {
            title: '评估时间',
            dataIndex: 'assessorDate',
            key: 'assessorDate',
            width: 120,
            resizable: true,
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width: 230,
            fixed: 'right',
            resizable: true,
          },
        ],
        4: [
          {
            title: '需求编号',
            dataIndex: 'demandNo',
            key: 'demandNo',
            width: 150,
            resizable: true,
          },
          {
            title: '公司名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 200,
            resizable: true,
          },
          {
            title: '业务员',
            dataIndex: 'salesman',
            key: 'salesman',
            width: 120,
            resizable: true,
          },
          {
            title: '所属行业',
            dataIndex: 'primaryIndustry',
            key: 'primaryIndustry',
            width: 150,
            resizable: true,
          },
          {
            title: '需求产品',
            dataIndex: 'demandProduct',
            key: 'demandProduct',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '设备名称',
            dataIndex: 'deviceName',
            key: 'deviceName',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '需求数量',
            dataIndex: 'demandCount',
            key: 'demandCount',
            width: 100,
            resizable: true,
          },
          {
            title: '分配人',
            dataIndex: 'assignee',
            key: 'assignee',
            width: 100,
            resizable: true,
          },
          {
            title: '转提案人',
            dataIndex: 'createdBy',
            key: 'createdBy',
            width: 140,
            resizable: true,
          },
          {
            title: '产品型号',
            dataIndex: 'productNumber',
            key: 'productNumber',
            width: 150,
            resizable: true,
          },
          {
            title: '含税单价',
            dataIndex: 'taxUnitPrice',
            key: 'taxUnitPrice',
            width: 100,
            resizable: true,
          },
          {
            title: '含税总价',
            dataIndex: 'taxPrice',
            key: 'taxPrice',
            width: 100,
            resizable: true,
          },
          {
            title: '产品交期',
            dataIndex: 'productDeliveryDay',
            key: 'productDeliveryDay',
            width: 100,
            resizable: true,
          },
          {
            title: '开发部门',
            dataIndex: 'productDept',
            key: 'productDept',
            width: 130,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '产品经理',
            dataIndex: 'productManager',
            key: 'productManager',
            width: 130,
            resizable: true,
          },
          {
            title: '提案类型',
            dataIndex: 'proposalType',
            key: 'proposalType',
            width: 120,
            statusObj: proposalTypeStatusObj,
            resizable: true,
          },
          {
            title: '提交时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
            width: 150,
            resizable: true,
          },
          {
            title: '评估时间',
            dataIndex: 'assessorDate',
            key: 'assessorDate',
            width: 120,
            resizable: true,
          },
          {
            title: '是否发送',
            dataIndex: 'isSend',
            key: 'isSend',
            width: 120,
            statusObj: {
              0: '未发送',
              1: '已发送',
            },
            resizable: true,
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width: 230,
            fixed: 'right',
            resizable: true,
          },
        ],
        5: [
          {
            title: '需求编号',
            dataIndex: 'demandNo',
            key: 'demandNo',
            width: 150,
            resizable: true,
          },
          {
            title: '公司名称',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 200,
            resizable: true,
          },
          {
            title: '业务员',
            dataIndex: 'salesman',
            key: 'salesman',
            width: 120,
            resizable: true,
          },
          {
            title: '所属行业',
            dataIndex: 'primaryIndustry',
            key: 'primaryIndustry',
            width: 150,
            resizable: true,
          },
          {
            title: '需求产品',
            dataIndex: 'demandProduct',
            key: 'demandProduct',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '设备名称',
            dataIndex: 'deviceName',
            key: 'deviceName',
            width: 150,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '需求数量',
            dataIndex: 'demandCount',
            key: 'demandCount',
            width: 100,
            resizable: true,
          },
          {
            title: '分配人',
            dataIndex: 'assignee',
            key: 'assignee',
            width: 100,
            resizable: true,
          },
          {
            title: '转提案人',
            dataIndex: 'createdBy',
            key: 'createdBy',
            width: 140,
            resizable: true,
          },
          {
            title: '开发部门',
            dataIndex: 'productDept',
            key: 'productDept',
            width: 130,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '产品经理',
            dataIndex: 'productManager',
            key: 'productManager',
            width: 100,
            resizable: true,
          },
          {
            title: '终止人',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            width: 100,
            resizable: true,
          },
          {
            title: '终止原因',
            dataIndex: 'abrogationReason',
            key: 'abrogationReason',
            width: 120,
            ellipsis: true,
            resizable: true,
          },
          {
            title: '提案类型',
            dataIndex: 'proposalType',
            key: 'proposalType',
            width: 120,
            statusObj: proposalTypeStatusObj,
            resizable: true,
          },
          {
            title: '是否发送',
            dataIndex: 'isSend',
            key: 'isSend',
            width: 120,
            statusObj: {
              0: '未发送',
              1: '已发送',
            },
            resizable: true,
          },
          {
            title: '提交时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
            width: 150,
            resizable: true,
          },
          {
            title: '终止时间',
            dataIndex: 'updatedTime',
            key: 'updatedTime',
            width: 150,
            resizable: true,
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width: 230,
            fixed: 'right',
            resizable: true,
          },
        ],
      } as Data,
      formItemListObj: {
        all: [
          {
            type: 'subInput',
            prop: 'demandNo',
            placeholder: '请输入需求编号',
            label: '需求编号',
          },
          {
            type: 'subInput',
            prop: 'companyName',
            placeholder: '请输入公司名称',
            label: '公司名称',
          },
          {
            type: 'subSelect',
            prop: 'isFollow',
            placeholder: '请选择是否关注',
            label: '是否关注:',
            optionList: [
              {
                value: 0,
                label: '未关注',
              },
              {
                value: 1,
                label: '关注',
              },
            ],
          },

          {
            type: 'slot',
            prop: 'primaryIndustry',
            placeholder: '请选择行业',
            label: '所属行业:',
          },
          {
            type: 'subSelect',
            prop: 'proposalType',
            placeholder: '请选择提案类型',
            label: '提案类型:',
            optionList: proposalTypeStatusList,
          },
          {
            type: 'subInput',
            prop: 'demandProduct',
            placeholder: '请输入需求产品',
            label: '需求产品',
          },
          {
            type: 'subInput',
            prop: 'deviceName',
            placeholder: '请输入设备名称',
            label: '设备名称',
          },
          {
            type: 'subInput',
            prop: 'productManager',
            placeholder: '请输入产品经理',
            label: '产品经理',
          },
        ],
        0: [
          {
            type: 'subInput',
            prop: 'demandNo',
            placeholder: '请输入需求编号',
            label: '需求编号',
          },
          {
            type: 'subInput',
            prop: 'companyName',
            placeholder: '请输入公司名称',
            label: '公司名称',
          },
          {
            type: 'subSelect',
            prop: 'isFollow',
            placeholder: '请选择是否关注',
            label: '是否关注:',
            optionList: [
              {
                value: 0,
                label: '未关注',
              },
              {
                value: 1,
                label: '关注',
              },
            ],
          },

          {
            type: 'slot',
            prop: 'primaryIndustry',
            placeholder: '请选择行业',
            label: '所属行业:',
          },
          {
            type: 'subInput',
            prop: 'demandProduct',
            placeholder: '请输入需求产品',
            label: '需求产品',
          },
          {
            type: 'subInput',
            prop: 'deviceName',
            placeholder: '请输入设备名称',
            label: '设备名称',
          },
          {
            type: 'subInput',
            prop: 'productManager',
            placeholder: '请输入产品经理',
            label: '产品经理',
          },
        ],
        1: [
          {
            type: 'subInput',
            prop: 'demandNo',
            placeholder: '请输入需求编号',
            label: '需求编号',
          },
          {
            type: 'subInput',
            prop: 'companyName',
            placeholder: '请输入公司名称',
            label: '公司名称',
          },
          {
            type: 'subInput',
            prop: 'assignee',
            placeholder: '请输入分配人',
            label: '分配人',
          },
          {
            type: 'subSelect',
            prop: 'isFollow',
            placeholder: '请选择是否关注',
            label: '是否关注:',
            optionList: [
              {
                value: 0,
                label: '未关注',
              },
              {
                value: 1,
                label: '关注',
              },
            ],
          },
          {
            type: 'slot',
            prop: 'primaryIndustry',
            placeholder: '请选择行业',
            label: '所属行业:',
          },
          {
            type: 'subInput',
            prop: 'demandProduct',
            placeholder: '请输入需求产品',
            label: '需求产品',
          },
          {
            type: 'subInput',
            prop: 'deviceName',
            placeholder: '请输入设备名称',
            label: '设备名称',
          },
          {
            type: 'subSelect',
            prop: 'proposalType',
            placeholder: '请选择提案类型',
            label: '提案类型:',
            optionList: proposalTypeStatusList,
          },
          {
            type: 'subInput',
            prop: 'productManager',
            placeholder: '请输入产品经理',
            label: '产品经理',
          },
        ],
        2: [
          {
            type: 'subInput',
            prop: 'demandNo',
            placeholder: '请输入需求编号',
            label: '需求编号',
          },
          {
            type: 'subInput',
            prop: 'companyName',
            placeholder: '请输入公司名称',
            label: '公司名称',
          },
          {
            type: 'subInput',
            prop: 'assignee',
            placeholder: '请输入分配人',
            label: '分配人',
          },
          {
            type: 'slot',
            prop: 'productDept',
            placeholder: '请选择开发部门',
            label: '开发部门',
          },
          {
            type: 'subSelect',
            prop: 'isFollow',
            placeholder: '请选择是否关注',
            label: '是否关注:',
            optionList: [
              {
                value: 0,
                label: '未关注',
              },
              {
                value: 1,
                label: '关注',
              },
            ],
          },
          {
            type: 'slot',
            prop: 'primaryIndustry',
            placeholder: '请选择行业',
            label: '所属行业:',
          },
          {
            type: 'subInput',
            prop: 'demandProduct',
            placeholder: '请输入需求产品',
            label: '需求产品',
          },
          {
            type: 'subInput',
            prop: 'deviceName',
            placeholder: '请输入设备名称',
            label: '设备名称',
          },
          {
            type: 'subSelect',
            prop: 'proposalType',
            placeholder: '请选择提案类型',
            label: '提案类型:',
            optionList: proposalTypeStatusList,
          },
          {
            type: 'subInput',
            prop: 'productManager',
            placeholder: '请输入产品经理',
            label: '产品经理',
          },
        ],
        3: [
          {
            type: 'subInput',
            prop: 'demandNo',
            placeholder: '请输入需求编号',
            label: '需求编号',
          },
          {
            type: 'subInput',
            prop: 'companyName',
            placeholder: '请输入公司名称',
            label: '公司名称',
          },
          {
            type: 'subInput',
            prop: 'assignee',
            placeholder: '请输入分配人',
            label: '分配人',
          },
          {
            type: 'slot',
            prop: 'productDept',
            placeholder: '请选择开发部门',
            label: '开发部门',
          },

          {
            type: 'subSelect',
            prop: 'isFollow',
            placeholder: '请选择是否关注',
            label: '是否关注:',
            optionList: [
              {
                value: 0,
                label: '未关注',
              },
              {
                value: 1,
                label: '关注',
              },
            ],
          },
          {
            type: 'slot',
            prop: 'primaryIndustry',
            placeholder: '请选择行业',
            label: '所属行业:',
          },
          {
            type: 'subInput',
            prop: 'demandProduct',
            placeholder: '请输入需求产品',
            label: '需求产品',
          },
          {
            type: 'subInput',
            prop: 'deviceName',
            placeholder: '请输入设备名称',
            label: '设备名称',
          },
          {
            type: 'subSelect',
            prop: 'proposalType',
            placeholder: '请选择提案类型',
            label: '提案类型:',
            optionList: proposalTypeStatusList,
          },
          {
            type: 'subInput',
            prop: 'productManager',
            placeholder: '请输入产品经理',
            label: '产品经理',
          },
        ],
        4: [
          {
            type: 'subInput',
            prop: 'demandNo',
            placeholder: '请输入需求编号',
            label: '需求编号',
          },
          {
            type: 'subInput',
            prop: 'companyName',
            placeholder: '请输入公司名称',
            label: '公司名称',
          },
          {
            type: 'subInput',
            prop: 'assignee',
            placeholder: '请输入分配人',
            label: '分配人',
          },
          {
            type: 'slot',
            prop: 'productDept',
            placeholder: '请选择开发部门',
            label: '开发部门',
          },

          {
            type: 'subSelect',
            prop: 'isFollow',
            placeholder: '请选择是否关注',
            label: '是否关注:',
            optionList: [
              {
                value: 0,
                label: '未关注',
              },
              {
                value: 1,
                label: '关注',
              },
            ],
          },
          {
            type: 'slot',
            prop: 'primaryIndustry',
            placeholder: '请选择行业',
            label: '所属行业:',
          },
          {
            type: 'subInput',
            prop: 'demandProduct',
            placeholder: '请输入需求产品',
            label: '需求产品',
          },
          {
            type: 'subInput',
            prop: 'deviceName',
            placeholder: '请输入设备名称',
            label: '设备名称',
          },
          {
            type: 'subSelect',
            prop: 'proposalType',
            placeholder: '请选择提案类型',
            label: '提案类型:',
            optionList: proposalTypeStatusList,
          },
          {
            type: 'subInput',
            prop: 'productManager',
            placeholder: '请输入产品经理',
            label: '产品经理',
          },
        ],
        5: [
          {
            type: 'subInput',
            prop: 'demandNo',
            placeholder: '请输入需求编号',
            label: '需求编号',
          },
          {
            type: 'subInput',
            prop: 'companyName',
            placeholder: '请输入公司名称',
            label: '公司名称',
          },
          {
            type: 'subInput',
            prop: 'assignee',
            placeholder: '请输入分配人',
            label: '分配人',
          },
          {
            type: 'subSelect',
            prop: 'isFollow',
            placeholder: '请选择是否关注',
            label: '是否关注:',
            optionList: [
              {
                value: 0,
                label: '未关注',
              },
              {
                value: 1,
                label: '关注',
              },
            ],
          },

          {
            type: 'slot',
            prop: 'primaryIndustry',
            placeholder: '请选择行业',
            label: '所属行业:',
          },
          {
            type: 'subInput',
            prop: 'demandProduct',
            placeholder: '请输入需求产品',
            label: '需求产品',
          },
          {
            type: 'subInput',
            prop: 'deviceName',
            placeholder: '请输入设备名称',
            label: '设备名称',
          },
          {
            type: 'subSelect',
            prop: 'proposalType',
            placeholder: '请选择提案类型',
            label: '提案类型:',
            optionList: proposalTypeStatusList,
          },
          {
            type: 'subInput',
            prop: 'productManager',
            placeholder: '请输入产品经理',
            label: '产品经理',
          },
        ],
      } as Data,
      optionList: [] as Data[],
      checked: false,
      modalList: [
        {
          title: '提案分配',
          prompt: '请完善详情中的信息，并确定客户全称无误',
          form: new Proposal(),
          formItemList: [
            {
              type: 'subInput',
              prop: 'demandProduct',
              placeholder: '请输入需求产品名称',
              label: '需求产品名称',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'deviceName',
              placeholder: '请输入应用设备名称',
              label: '应用设备名称',
              disabled: true,
            },
            {
              type: 'slot',
              prop: 'companyName',
              placeholder: '请输入客户名称',
              label: '客户名称',
            },
            {
              type: 'slot',
              prop: 'productManager',
              placeholder: '请输入产品经理',
              label: '产品经理',
            },
            {
              type: 'subInput',
              prop: 'productDept',
              placeholder: '请输入产品部门',
              label: '产品部门',
              readonly: true,
            },
            {
              type: 'subSelect',
              prop: 'proposalType',
              placeholder: '请选择需求类型',
              label: '需求类型:',
              optionList: proposalTypeStatusList,
            },
            {
              type: 'slot',
              prop: 'expectedTime',
              placeholder: '请输入期望完成',
              label: '期望完成天数',
              optionList: [
                {
                  value: 2,
                  label: '2天',
                },
                {
                  value: 5,
                  label: '5天',
                },
                {
                  value: 7,
                  label: '7天',
                },
              ],
            },
          ] as FormItemListType<Proposal>,
          //表单数据
          rules: {
            companyName: [{ required: true, message: '公司名称不可为空', trigger: 'change' }],
            productManager: [{ required: true, message: '产品经理不可为空', trigger: 'blur' }],
            productDept: [
              {
                required: true,
                message: '产品部门不可为空',
                trigger: 'blur',
              },
            ],
            proposalType: [
              { required: true, message: '使用环境不可为空', trigger: 'change', type: 'number' },
            ],
            expectedTime: [
              {
                required: true,
                message: '期望完成天数不可为空',
                trigger: 'change',
                type: 'number',
              },
            ],
          },
          visible: false,
          ok: () => {
            formRef.value[0]
              .validateFields()
              .then(() => {
                console.log('成功')
                proposalApi
                  .DemandDistribute({
                    id: pageData.modalList[0].form.id,
                    productManager: pageData.modalList[0].form.productManager,
                    productManagerId: pageData.modalList[0].form.productManagerId,
                    productDept: pageData.modalList[0].form.productDept,
                    productDeptNo: pageData.modalList[0].form.productDeptNo,
                    priority: pageData.modalList[0].form.priority,
                    status: pageData.modalList[0].form.status,
                    proposalType: pageData.modalList[0].form.proposalType,
                    expectedTime: pageData.modalList[0].form.expectedTime,
                    companyName: pageData.modalList[0].form.companyName,
                    contact: pageData.modalList[0].form.contact,
                    demandProduct: pageData.modalList[0].form.demandProduct,
                    deviceName: pageData.modalList[0].form.deviceName,
                    demandDesc: pageData.modalList[0].form.demandDesc,
                  })
                  .then(() => {
                    message.success('分配成功')
                    pageData.modalList[0].visible = false
                    initeList()
                  })
                  .catch((err) => {
                    message.error(err)
                  })
              })
              .catch(() => {
                console.log('验证失败')
              })
          },
          openBefore: () => {
            if (pageData.selectedRowKeys.length !== 1) {
              message.warning('请选择其中一条数据！')
              return
            }
            pageData.modalList[0].form = new Proposal()
            let data = pageData.dataSource.find((item) => item.id === pageData.selectedRowKeys[0])
            Object.assign(pageData.modalList[0].form, data)
            pageData.modalList[0].visible = true
          },
          cancel: () => {
            formRef.value[0].resetFields()
          },
        },
        {
          title: '终止',
          form: new Proposal(),
          formItemList: [
            {
              type: 'subInput',
              prop: 'demandProduct',
              placeholder: '请输入需求产品名称',
              label: '需求产品',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'deviceName',
              placeholder: '请输入应用设备名称',
              label: '应用设备名称',
              disabled: true,
            },
            {
              type: 'textarea',
              prop: 'abrogationReason',
              placeholder: '该原因用于回复客户，请谨慎填写。',
              label: '终止原因',
            },
          ] as FormItemListType<Proposal>,
          //表单数据
          rules: {},
          visible: false,
          ok: () => {
            formRef.value[1]
              .validateFields()
              .then(() => {
                console.log('成功')
                proposalApi
                  .Termination(pageData.modalList[1].form)
                  .then(() => {
                    message.success('终止成功')
                    pageData.modalList[1].visible = false
                    initeList()
                  })
                  .catch((err) => {
                    message.error(err)
                  })
              })
              .catch(() => {
                console.log('验证失败')
              })
          },
          openBefore: () => {
            if (pageData.selectedRowKeys.length !== 1) {
              message.warning('请选择其中一条数据！')
              return
            }
            pageData.modalList[1].form = new Proposal()
            let data = pageData.dataSource.find((item) => item.id === pageData.selectedRowKeys[0])
            Object.assign(pageData.modalList[1].form, data)
            pageData.modalList[1].visible = true
          },
          cancel: () => {
            formRef.value[1].resetFields()
          },
        },
        {
          title: '提案再分配',
          prompt: '请完善详情中的信息，并确定客户全称无误',
          form: new Proposal(),
          formItemList: [
            {
              type: 'subInput',
              prop: 'demandProduct',
              placeholder: '请输入需求产品名称',
              label: '需求产品名称',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'deviceName',
              placeholder: '请输入应用设备名称',
              label: '应用设备名称',
              disabled: true,
            },
            {
              type: 'slot',
              prop: 'companyName',
              placeholder: '请输入客户名称',
              label: '客户名称',
            },
            {
              type: 'slot',
              prop: 'productManager',
              placeholder: '请输入产品经理',
              label: '产品经理',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange: (value: string, option: any) => {
                pageData.modalList[2].form.productManager = option.label.split('-')[0]
                // pageData.modalList[2].form.productDept = option.label.split('-')[1]
                pageData.modalList[2].form.productManagerId = option.value.split('//')[0]
                // pageData.modalList[2].form.productDeptNo = option.value.split('//')[1]
                proposalApi
                  .ListDeptManager({
                    data: {
                      adminName: option.name,
                    },
                    noLoading: true,
                  })
                  .then((res) => {
                    if (res.data && res.data.length > 0) {
                      pageData.modalList[2].form.productDept = res.data[0].deptName as string
                      pageData.modalList[2].form.productDeptNo = res.data[0].id as string
                    }
                  })
              },
              handleChange: debounce((val: string) => {
                if (val !== '' && val.length >= 2) {
                  productOptionList.value = []
                  proposalApi
                    .ListDeptAndManager({
                      data: {
                        name: val,
                        pageSize: 30,
                        job: 'admin',
                      },
                      noLoading: true,
                    })
                    .then((res) => {
                      if (res.data) {
                        console.log(res.data, 'res.data')

                        productOptionList.value = res.data.map((item) => {
                          return {
                            label: item.empName + '-' + item.dptName,
                            value: item.id + '//' + item.dptNo,
                            name: item.name,
                          }
                        })
                      }
                    })
                    .catch((err) => {
                      message.error(err)
                    })
                } else if (val != '' && val.length === 1) {
                  message.warning('请最少输入两个字符进行查询！')
                }
              }, 500),
            },
            {
              type: 'subInput',
              prop: 'productDept',
              placeholder: '请输入产品部门',
              label: '产品部门',
              readonly: true,
            },
            {
              type: 'subSelect',
              prop: 'proposalType',
              placeholder: '请选择需求类型',
              label: '需求类型:',
              optionList: proposalTypeStatusList,
            },
            {
              type: 'subSelect',
              prop: 'expectedTime',
              placeholder: '请输入期望完成时间',
              label: '期望完成时间',
              optionList: [
                {
                  value: 2,
                  label: '2天',
                },
                {
                  value: 5,
                  label: '5天',
                },
                {
                  value: 7,
                  label: '7天',
                },
              ],
            },
          ] as FormItemListType<Proposal>,
          //表单数据
          rules: {
            companyName: [{ required: true, message: '公司名称不可为空', trigger: 'change' }],
            productManager: [{ required: true, message: '产品经理不可为空', trigger: 'blur' }],
            productDept: [
              {
                required: true,
                message: '产品部门不可为空',
                trigger: 'blur',
              },
            ],
            proposalType: [
              { required: true, message: '使用环境不可为空', trigger: 'change', type: 'number' },
            ],
            expectedTime: [
              {
                required: true,
                message: '期望完成时间不可为空',
                trigger: 'change',
                type: 'number',
              },
            ],
          },
          visible: false,
          ok: () => {
            formRef.value[2]
              .validateFields()
              .then(() => {
                console.log('成功')
                proposalApi
                  .DemandDistribute({
                    id: pageData.modalList[2].form.id,
                    productManager: pageData.modalList[2].form.productManager,
                    productManagerId: pageData.modalList[2].form.productManagerId,
                    productDept: pageData.modalList[2].form.productDept,
                    productDeptNo: pageData.modalList[2].form.productDeptNo,
                    priority: pageData.modalList[2].form.priority,
                    status: pageData.modalList[2].form.status,
                    proposalType: pageData.modalList[2].form.proposalType,
                    expectedTime: pageData.modalList[2].form.expectedTime,
                    companyName: pageData.modalList[2].form.companyName,
                    contact: pageData.modalList[2].form.contact,
                    demandProduct: pageData.modalList[2].form.demandProduct,
                    deviceName: pageData.modalList[2].form.deviceName,
                    demandDesc: pageData.modalList[2].form.demandDesc,
                  })
                  .then(() => {
                    message.success('再分配成功')
                    pageData.modalList[2].visible = false
                    initeList()
                  })
                  .catch((err) => {
                    message.error(err)
                  })
              })
              .catch(() => {
                console.log('验证失败')
              })
          },
          openBefore: () => {
            if (pageData.selectedRowKeys.length !== 1) {
              message.warning('请选择其中一条数据！')
              return
            }
            pageData.modalList[2].form = new Proposal()
            let data = pageData.dataSource.find((item) => item.id === pageData.selectedRowKeys[0])
            Object.assign(pageData.modalList[2].form, data)
            pageData.modalList[2].visible = true
          },
          cancel: () => {
            formRef.value[2].resetFields()
          },
        },
        {
          title: '提案开发',
          form: new Proposal(),
          formItemList: [
            {
              type: 'subInput',
              prop: 'companyName',
              placeholder: '请输入客户名称',
              label: '客户名称',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'demandProduct',
              placeholder: '请输入需求产品名称',
              label: '需求产品名称',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'deviceName',
              placeholder: '请输入应用设备名称',
              label: '应用设备名称',
              disabled: true,
            },
            {
              type: 'slot',
              prop: 'estimatedTime',
              placeholder: '请输入预计完成时间',
              label: '预计完成时间',
            },
          ] as FormItemListType<Proposal>,
          //表单数据
          rules: {},
          visible: false,
          ok: () => {
            formRef.value[3]
              .validateFields()
              .then(() => {
                console.log('成功')
                proposalApi
                  .DemandDevelop(pageData.modalList[3].form)
                  .then(() => {
                    message.success('成功')
                    pageData.modalList[3].visible = false
                    initeList()
                  })
                  .catch((err) => {
                    message.error(err)
                  })
              })
              .catch(() => {
                console.log('验证失败')
              })
          },
          openBefore: () => {
            if (pageData.selectedRowKeys.length !== 1) {
              message.warning('请选择其中一条数据！')
              return
            }
            pageData.modalList[3].form = new Proposal()
            let data = pageData.dataSource.find((item) => item.id === pageData.selectedRowKeys[0])
            Object.assign(pageData.modalList[3].form, data)
            pageData.modalList[3].visible = true
          },
          cancel: () => {
            formRef.value[3].resetFields()
          },
        },
        {
          title: '报价',
          form: new Proposal(),
          formItemList: [
            {
              type: 'subInput',
              prop: 'demandProduct',
              placeholder: '请输入需求产品名称',
              label: '需求产品名称',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'deviceName',
              placeholder: '请输入应用设备名称',
              label: '应用设备名称',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'customerNo',
              placeholder: '请输入客户编号',
              label: '客户编号',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'demandNo',
              placeholder: '请输入询价单号',
              label: '询价单号',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'productNumber',
              placeholder: '请输入产品型号',
              label: '产品型号',
            },
          ] as FormItemListType<Proposal>,
          //表单数据
          rules: {
            productNumber: [{ required: true, message: '产品型号不可为空', trigger: 'blur' }],
          },
          visible: false,
          ok: () => {
            formRef.value[4]
              .validateFields()
              .then(() => {
                console.log('成功')
                let data = {
                  copQuoteVO: {
                    customerCode: '',
                    customerQuoteNo: '',
                    purchaser: '',
                    purchaserPhone: '',
                  },
                  copQuoteDetailVOList: [
                    {
                      customerMaterialSpecification: '',
                      customerQuantity: 0,
                      customerUnit: '',
                      customerQuoteNumber: 1,
                      customerPartNo: '',
                      customerProductName: '',
                      materialSpecification: '',
                    },
                  ],
                }
                data.copQuoteVO.customerCode = pageData.modalList[4].form.customerNo as string
                data.copQuoteVO.customerQuoteNo = pageData.modalList[4].form.demandNo as string
                data.copQuoteVO.purchaser = pageData.modalList[4].form.contact as string
                data.copQuoteVO.purchaserPhone = pageData.modalList[4].form.contactPhone as string
                data.copQuoteDetailVOList[0].customerProductName = pageData.modalList[4].form
                  .demandProduct as string
                data.copQuoteDetailVOList[0].customerMaterialSpecification = pageData.modalList[4]
                  .form.productNumber as string
                data.copQuoteDetailVOList[0].customerQuantity = pageData.modalList[4].form
                  .demandCount as number
                proposalApi
                  .CreateQuote(data)
                  .then(() => {
                    message.success('成功')
                    pageData.modalList[4].visible = false
                    initeList()
                  })
                  .catch((err) => {
                    message.error(err)
                  })
              })
              .catch(() => {
                console.log('验证失败')
              })
          },
          openBefore: () => {
            if (pageData.selectedRowKeys.length !== 1) {
              message.warning('请选择其中一条数据！')
              return
            }
            pageData.modalList[4].form = new Proposal()
            let data = pageData.dataSource.find((item) => item.id === pageData.selectedRowKeys[0])
            Object.assign(pageData.modalList[4].form, data)
            pageData.modalList[4].visible = true
          },
          cancel: () => {
            formRef.value[4].resetFields()
          },
        },
        {
          title: '发送',
          form: new Proposal(),
          formItemList: [
            {
              type: 'subInput',
              prop: 'demandNo',
              placeholder: '请输入需求编号',
              label: '需求编号',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'companyName',
              placeholder: '请输入公司名称',
              label: '公司名称',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'assignee',
              placeholder: '请输入分配人',
              label: '分配人',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'contactPhone',
              placeholder: '请输入联系电话',
              label: '联系电话',
              disabled: true,
            },
          ] as FormItemListType<Proposal>,
          //表单数据
          rules: {
            productNumber: [{ required: true, message: '产品型号不可为空', trigger: 'blur' }],
          },
          visible: false,
          ok: () => {
            formRef.value[5]
              .validateFields()
              .then(() => {
                console.log('成功')
                let data = {
                  demandNo: pageData.modalList[5].form.demandNo,
                  demandMsg: `${window.location.host}/submitRecord/detail/${pageData.modalList[5].form.demandNo}`,
                }
                proposalApi
                  .sendMessage(data)
                  .then(() => {
                    message.success('发送成功')
                    pageData.modalList[5].visible = false
                    initeList()
                  })
                  .catch((err) => {
                    message.error(err)
                  })
              })
              .catch(() => {
                console.log('验证失败')
              })
          },
          openBefore: () => {
            if (pageData.selectedRowKeys.length !== 1) {
              message.warning('请选择其中一条数据！')
              return
            }
            pageData.modalList[5].form = new Proposal()
            let data = pageData.dataSource.find((item) => item.id === pageData.selectedRowKeys[0])
            Object.assign(pageData.modalList[5].form, data)
            pageData.modalList[5].visible = true
          },
          cancel: () => {
            formRef.value[5].resetFields()
          },
          okText: '发送',
        },
        {
          title: '转正式型号',
          form: new Proposal(),
          formItemList: [
            {
              type: 'subInput',
              prop: 'productNumber',
              placeholder: '请输入临时型号',
              label: '临时型号',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'officialProductNumber',
              placeholder: '请输入正式型号',
              label: '正式型号',
            },
          ] as FormItemListType<Proposal>,
          //表单数据
          rules: {
            officialProductNumber: [
              { required: true, message: '正式型号不可为空', trigger: 'blur' },
            ],
          },
          visible: false,
          ok: () => {
            formRef.value[6]
              .validateFields()
              .then(() => {
                console.log('成功')
                let data = {
                  id: pageData.modalList[6].form.id,
                  officialProductNumber: pageData.modalList[6].form.officialProductNumber,
                  status: pageData.modalList[6].form.status,
                }
                proposalApi
                  .Positive(data)
                  .then(() => {
                    message.success('成功')
                    pageData.modalList[6].visible = false
                    initeList()
                  })
                  .catch((err) => {
                    message.error(err)
                  })
              })
              .catch(() => {
                console.log('验证失败')
              })
          },
          openBefore: () => {
            if (pageData.selectedRowKeys.length !== 1) {
              message.warning('请选择其中一条数据！')
              return
            }
            pageData.modalList[6].form = new Proposal()
            let data = pageData.dataSource.find((item) => item.id === pageData.selectedRowKeys[0])
            Object.assign(pageData.modalList[6].form, data)
            pageData.modalList[6].visible = true
          },
          cancel: () => {
            formRef.value[6].resetFields()
          },
        },
        {
          title: '终止发送',
          form: new Proposal(),
          formItemList: [
            {
              type: 'subInput',
              prop: 'demandNo',
              placeholder: '请输入需求编号',
              label: '需求编号',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'companyName',
              placeholder: '请输入公司名称',
              label: '公司名称',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'assignee',
              placeholder: '请输入分配人',
              label: '分配人',
              disabled: true,
            },
            {
              type: 'subInput',
              prop: 'assigneePhone',
              placeholder: '请输入联系电话',
              label: '联系电话',
              disabled: true,
            },
            {
              type: 'textarea',
              prop: 'abrogationReason',
              placeholder: '终止原因',
              label: '终止原因',
              disabled: true,
            },
          ] as FormItemListType<Proposal>,
          //表单数据
          rules: {},
          visible: false,
          ok: () => {
            formRef.value[7]
              .validateFields()
              .then(() => {
                console.log('成功')
                let data = {
                  demandNo: pageData.modalList[7].form.demandNo,
                  demandMsg: `${window.location.host}/submitRecord/detail/${pageData.modalList[7].form.demandNo}`,
                }
                proposalApi
                  .sendMessage(data)
                  .then(() => {
                    message.success('发送成功')
                    pageData.modalList[7].visible = false
                    initeList()
                  })
                  .catch((err) => {
                    message.error(err)
                  })
              })
              .catch(() => {
                console.log('验证失败')
              })
          },
          openBefore: () => {
            if (pageData.selectedRowKeys.length !== 1) {
              message.warning('请选择其中一条数据！')
              return
            }
            pageData.modalList[7].form = new Proposal()
            let data = pageData.dataSource.find((item) => item.id === pageData.selectedRowKeys[0])
            Object.assign(pageData.modalList[7].form, data)
            pageData.modalList[7].visible = true
          },
          cancel: () => {
            formRef.value[7].resetFields()
          },
          okText: '发送',
        },
      ],
    })
    const getCount = () => {
      homeApi
        .GetIndexDemand({
          begin: '2022-05-18 14:36:04',
          end: '2022-05-19 14:38:04',
          noLoading: true,
        })
        .then((res) => {
          let count =
            (res.statusZeroCount as number) +
            (res.statusOneCount as number) +
            (res.statusTwoCount as number) +
            (res.statusThreeCount as number) +
            (res.statusFourCount as number) +
            (res.statusFiveCount as number)
          pageData.tabList[0].title = `所有（${count}）`
          pageData.tabList[1].title = `待分配（${res.statusZeroCount}）`
          pageData.tabList[2].title = `评估中（${res.statusOneCount}）`
          pageData.tabList[3].title = `开发中（${res.statusTwoCount}）`
          pageData.tabList[4].title = `报价中（${res.statusThreeCount}）`
          pageData.tabList[5].title = `已完成（${res.statusFourCount}）`
          pageData.tabList[6].title = `已终止（${res.statusFiveCount}）`
        })
        .catch((err) => {
          message.error(err)
        })
    }
    let route = useRoute()
    onBeforeRouteUpdate((to, from) => {
      console.log(from.name)
      store.commit('setRegForm', [from.name, pageData.selectFrom])
    })

    productApi //获取产品部门选项
      .GetProductDept()
      .then((res) => {
        pageData.optionList = res.map((item) => {
          return {
            label: item.deptName,
            value: item.deptCode,
          }
        })
      })
      .catch((err) => {
        console.log(err)
        message.error(err || '获取部门失败')
      })
    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.selectFrom.pageSize,
        currentPage: pageData.selectFrom.currentPage,
        total: pageData.selectFrom.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.selectFrom.pageSize = pageSize
          pageData.selectFrom.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.selectFrom.pageSize = size
          pageData.selectFrom.currentPage = current
          initeList()
        },
      }
      return page
    })
    //获取表格信息
    const initeList = () => {
      pageData.selectedRowKeys.length = 0
      proposalApi
        .DemandList(pageData.selectFrom)
        .then((res) => {
          pageData.dataSource = []
          if (res.data) {
            Object.assign(pageData.dataSource, res.data)
          }
          console.log(pageData.dataSource)

          pageData.selectFrom.currentPage = res.currentPage as number
          pageData.selectFrom.pageSize = res.pageSize
          pageData.selectFrom.dataCount = res.dataCount
          getCount()
        })
        .catch((err) => {
          message.error(err)
        })
    }

    //重置数据
    const selectList = () => {
      pageData.selectFrom.currentPage = 1
      pageData.selectFrom.pageSize = 10
      initeList()
    }
    // //如果有缓存的查询数据 则采用缓存的数据
    if (store.state.regForm[route.name as string] !== undefined) {
      pageData.selectFrom = JSON.parse(JSON.stringify(store.state.regForm[route.name as string]))
      initeList()
    }
    //删除按钮
    const delData = () => {
      if (pageData.selectedRowKeys.length === 0) {
        message.warning('至少选中一条')
        return
      }
      const ok = () => {
        proposalApi
          .DelProposal(pageData.selectedRowKeys)
          .then(() => {
            message.success('删除成功')
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      }
      const onCancel = () => {
        console.log('Cancel')
      }
      deleteModal(ok, onCancel, '是否确定删除对应记录', '该记录一旦确定或将有无法恢复的风险')
    }
    //搜索
    const search = () => {
      console.log(pageData.selectFrom)
    }
    //
    const reset = () => {
      let status = pageData.selectFrom.data.status
      pageData.selectFrom.data = new Proposal()
      pageData.selectFrom.data.status = status
      pageData.primaryIndustry = []
      selectList()
    }
    //关注与取消关注页面
    const demandFollow = (id: string, isFollow: number) => {
      if (isFollow === 1) {
        proposalApi
          .demandFollowCancel(id)
          .then(() => {
            message.success('取消关注成功')
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      } else {
        proposalApi
          .demandFollow(id)
          .then(() => {
            message.success('关注成功')
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      }
    }
    //行业改变事件
    const cascaderChange = (value: string[]) => {
      pageData.selectFrom.data.primaryIndustry = value[0]
      pageData.selectFrom.data.secondaryIndustry = value[1]
    }
    // 一级行业获取
    AchieveApi.queryOptionalType({ optionalType: 'primaryIndustry' })
      .then((res) => {
        if (res) {
          pageData.options = res.map((item) => {
            return {
              label: item.name,
              value: item.name,
              custom: item.id,
              isLeaf: false,
            }
          })
        }
      })
      .catch((err) => {
        message.error(err || '获取行业信息失败')
      })
    //获取二级行业
    const loadData = (val: Data[]) => {
      console.log(val)
      AchieveApi.queryOptionalType({
        optionalType: 'secondaryIndustry',
        subParameter: val[0].custom,
        noLoading: true,
      })
        .then((res) => {
          val[0].children =
            res?.map((item) => {
              return {
                label: item.name,
                value: item.name,
                custom: item.id,
                isLeaf: true,
              }
            }) || []
        })
        .catch((err) => {
          message.error(err)
        })
    }
    //监听tab改变 表格列筛选项目
    watch(
      () => pageData.selectFrom.data.status,
      () => {
        selectList()
      }
    )
    const columns = computed(() => {
      let data =
        pageData.selectFrom.data.status === null
          ? pageData.columnsListObj.all
          : pageData.columnsListObj[pageData.selectFrom.data.status as number]
      return data as ColumnsTypeData[]
    })
    const formItemList = computed(() => {
      if (pageData.selectFrom.data.status === null) {
        return pageData.formItemListObj.all as FormItemListType<Proposal>
      } else {
        return pageData.formItemListObj[
          pageData.selectFrom.data.status as number
        ] as FormItemListType<Proposal>
      }
    })
    //监听超时勾选
    watch(
      () => pageData.checked,
      (newVal) => {
        pageData.selectFrom.data.isOvertime = newVal ? 1 : 0
      }
    )
    //打开对应弹窗
    const openModal = (title: string) => {
      let index = pageData.modalList.findIndex((item) => item.title === title)
      pageData.modalList[index].openBefore()
    }
    //禁止选择过去的日期
    const disabledDate = (current: Dayjs) => {
      return current && current <= dayjs().endOf('day')
    }
    //预计完成时间改变事件
    const dateChange = (date: string) => {
      let difference = Math.ceil(
        (new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
      )
      pageData.difference = difference > 0 ? difference : 0
    }
    //导出
    const exportData = () => {
      proposalApi
        .ExportProposal(pageData.selectFrom.data)
        .then((res) => {
          console.log(res)
          let blob = new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' })
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.download = ''
          a.href = url
          a.click()
          a.remove()
        })
        .catch((err) => {
          message.error(err)
        })
    }
    //再分配
    const redistribute = () => {
      if (pageData.selectedRowKeys.length !== 1) {
        message.warning('请选中要重新分配的数据')
        return
      }
      let data = pageData.dataSource.find((item) => item.id === pageData.selectedRowKeys[0])
      proposalApi
        .DemandRedistribute({ id: data?.id, status: data?.status })
        .then(() => {
          message.success('已转到待分配状态')
          initeList()
        })
        .catch((err) => {
          message.error(err)
        })
    }
    //获取状态
    const status = computed(() => pageData.selectFrom.data.status as number)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange = (value: string, option: any) => {
      pageData.modalList[0].form.productManager = option.label.split('-')[0]
      // pageData.modalList[0].form.productDept = option.label.split('-')[1]
      pageData.modalList[0].form.productManagerId = option.value.split('//')[0]
      // pageData.modalList[0].form.productDeptNo = option.value.split('//')[1]
      console.log(option.value)
      if (option.value.indexOf('additionalPost') > -1) {
        pageData.modalList[0].form.productDept = '兼职部门'
        pageData.modalList[0].form.productDeptNo = option.value.split('//')[2]
        return
      }
      proposalApi
        .ListDeptManager({
          data: {
            adminName: option.name,
          },
          noLoading: true,
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            pageData.modalList[0].form.productDept = res.data[0].deptName as string
            pageData.modalList[0].form.productDeptNo = res.data[0].id as string
          }
        })
    }
    const handleChange = debounce((val: string) => {
      if (val !== '' && val.length >= 2) {
        productOptionList.value = []
        proposalApi
          .ListDeptAndManager({
            data: {
              name: val,
              pageSize: 30,
              job: 'admin',
            },
            noLoading: true,
          })
          .then((res) => {
            console.log(res, '产品经理')
            if (res.data) {
              productOptionList.value = res.data.map((item) => {
                return {
                  label: `${item.name}(${item.code})-${item.deptName}`,
                  value: item.id + '//' + item.dept,
                  name: item.name,
                }
              })
              res.data.forEach((item) => {
                let arr = ['1', '2', '3']
                arr.forEach((index: string) => {
                  if (item['additionalPost' + index]) {
                    productOptionList.value.push({
                      label:
                        `${item.name}(${item.code})` +
                        '-' +
                        item['additionalPost' + index + 'Name'],
                      value: item.id + '//' + 'additionalPost' + index + '//' + item.dept,
                      name: item.name,
                    })
                  }
                })
              })
            }
          })
          .catch((err) => {
            message.error(err)
          })
      } else if (val != '' && val.length === 1) {
        message.warning('请最少输入两个字符进行查询！')
      }
    }, 500)

    // tab栏目change事件
    const tabChange = (key: number) => {
      pageData.selectFrom.data = {} as Proposal
      pageData.selectFrom.data.status = key
      pageData.isDrawer = false
      pageData.isShowDrawer = false
      nextTick(() => {
        pageData.isDrawer = true
      })
    }

    const pageName = computed(() => {
      return `proposalInformation-${pageData.selectFrom.data.status ?? 'all'}`
    })

    const downloadFile = (id: string) => {
      proposalApi.DownloadFile({ id }).then((res) => {
        console.log(res)
        let blob = new Blob([res], { type: 'application/msword;charset=UTF-8' })
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.download = ''
        a.href = url
        a.click()
        a.remove()
      })
    }

    const history = (demandNo: string) => {
      proposalApi
        .DemandLog({ demandNo })
        .then((res) => {
          pageData.historyInfo = res
          if (res.length) {
            pageData.historyInfo.reverse()
            pageData.historyType = true
          } else {
            message.warning('数据为空')
          }
        })
        .catch((err) => {
          message.error(err || '获取信息失败')
        })
    }

    return {
      history,
      tabChange,
      onChange,
      handleChange,
      redistribute,
      exportData,
      dateChange,
      disabledDate,
      productOptionList,
      openModal,
      loadData,
      cascaderChange,
      demandFollow,
      ...toRefs(pageData),
      search,
      selectList,
      delData,
      reset,
      buttonObj,
      currentConfigure,
      labelCol: { style: { width: '110px' } },
      wrapperCol: { style: { width: '100%' } },
      formRef,
      columns,
      formItemList,
      status,
      downloadFile,
      updateColumns,
      pageName,
    }
  },
})
