
import { defineComponent, toRefs, reactive } from 'vue'
import { ReqCustomerMessageClass } from '@/model/system/customer'
import customerApi from '@/axios/api/customerApi'
import Data from '@/model/common/Data'
import { message } from 'ant-design-vue'
import { debounce } from 'lodash-es'
export default defineComponent({
  props: ['size', 'restrict', 'value'],
  emits: ['selectKey', 'blur'],
  setup(props, { emit }) {
    const blur = () => {
      emit('blur')
    }
    const state = reactive({
      data: [] as Data[],
      fetching: false,
      reqForm: new ReqCustomerMessageClass(),
    })
    const fetchUser = debounce((value: string) => {
      if (!value) return
      state.reqForm.data.customerName = value
      Object.assign(state.reqForm, { noLoading: true })
      state.reqForm.data.restrict = props.restrict || 0
      customerApi
        .GetCustomerList(state.reqForm)
        .then((res) => {
          if (res.data) {
            state.data = res.data.map((item) => {
              let obj = {
                value: item.id,
                label: item.customerName,
              }
              Object.assign(obj, item)
              return obj
            })
          } else {
            state.data = []
          }
        })
        .catch((err) => {
          message.error(err)
        })
    }, 300)
    const change = (value: string) => {
      console.log(value)
      let data = state.data.find((item) => item.id === value)
      console.log(data)
      emit('selectKey', data)
    }
    return {
      ...toRefs(state),
      fetchUser,
      change,
      blur,
    }
  },
})
