import { $axios } from '../config/axios'
import {
  RegFillClass,
  ResFillClass,
  FillClass,
} from '@/model/competitiveAnalysis/fillInformation/fillInformation'
import { ProductDept } from '@/types/comm'
class ProductApi {
  //分页获取
  ProductList(data: RegFillClass): Promise<ResFillClass> {
    return $axios.post('/api/product/list', data)
  }
  //获取产品部门
  GetProductDept(): Promise<ProductDept[]> {
    return $axios.post('/api/product/getProductDept', {})
  }
  //根据规格获取竞品列表
  GetProduct(data: unknown): Promise<FillClass[]> {
    return $axios.post('/api/info/getProduct', data)
  }
  //根据料号获取erp物料
  GetProductByCode(data: unknown): Promise<FillClass> {
    return $axios.post('/api/product/getProductByCode', data)
  }
}
// 单列模式返回对象
let productApi
export default (() => {
  if (productApi) return productApi
  productApi = new ProductApi()
  return productApi
})()
