import { Modal } from 'ant-design-vue'
import { createVNode } from 'vue'
export const deleteModal = (
  ok?: () => void,
  cancel?: () => void,
  title?: string,
  content?: string
): unknown => {
  const svg = createVNode(
    'svg',
    {
      class: 'icon',
      'aria-hidden': 'true',
      style: 'margin-right:5px;font-size:16px;vertical-align:text-top;',
    },
    [createVNode('use', { 'xlink:href': '#icon-tishi_hongse' })]
  )
  return Modal.confirm({
    title: () => (title ? title : '批量删除'),
    icon: svg,
    content: content ? content : '您确实要批量删除选择的数据吗？',
    okText: '删除',
    okButtonProps: {
      danger: true,
    },
    onOk() {
      if (ok) ok()
    },
    onCancel() {
      if (cancel) cancel()
    },
    class: 'test',
    width: '450px',
  })
}

export const deleteOneModal = (
  ok?: () => void,
  cancel?: () => void,
  title?: string,
  content?: string
): unknown => {
  const svg = createVNode(
    'svg',
    {
      class: 'icon',
      'aria-hidden': 'true',
      style: 'margin-right:5px;font-size:16px;vertical-align:text-top;',
    },
    [createVNode('use', { 'xlink:href': '#icon-tishi_hongse' })]
  )
  return Modal.confirm({
    title: () => (title ? title : '系统提示'),
    icon: svg,
    content: content ? content : '您确实要删除选择的数据吗？',
    okText: '删除',
    okButtonProps: {
      danger: true,
    },
    onOk() {
      if (ok) ok()
    },
    onCancel() {
      if (cancel) cancel()
    },
    class: 'test',
    width: '450px',
  })
}
