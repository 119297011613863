/**
 * 需求信息实体类和接口
 */
import { ReqPage } from '@/model/common/reqPage'
import Data from '../common/Data'
import { Demand } from './demand'
//同行信息类
export class Proposal {
  id: string | undefined // "string //主键"
  demandNo: string | undefined // 需求编号"
  companyName: string | undefined //公司名称
  customerNo: string | undefined //客户编号
  contact: string | undefined //联系人
  contactPhone: string | undefined //联系人手机号
  salesman: string | undefined //业务员
  primaryIndustry: string | undefined //所属行业-一级行业
  secondaryIndustry: string | undefined //所属行业-二级行业
  demandProduct: string | undefined //需求产品名称
  deviceName: string | undefined //应用设备名称
  demandCount: number | undefined //预计需求数量
  useEnv: string | undefined //使用环境
  demandDate: string | undefined //需求时间
  priority: number | undefined //优先级0:重要紧急 1:重要不紧急 2:紧急不重要 3:不重要不紧急
  assignee: string | undefined //分配人
  assigneeId: string | undefined //分配人id
  assigneePhone: string | undefined //分配人电话
  industryStandard: string | undefined //产品相关的国内、国际行业标准、安全要求、环境保护要求
  lifeRequirements: string | undefined //寿命要求
  mainMaterial: string | undefined //主要材质
  bear: string | undefined //承载
  processingRequired: string | undefined //表面处理要求
  operatingTemperature: string | undefined //使用温度范围
  storageRequired: string | undefined //包装贮存要求
  productDept: string | undefined //产品部门
  productDeptNo: string | undefined //产品部门编号
  productManager: string | undefined //产品经理
  productManagerId: string | undefined //产品经理id
  assessor: string | undefined //评估人
  assessorId: string | undefined //评估人id
  assessorDate: string | undefined //评估时间
  feedbackTime: string | undefined //反馈时间
  isDevelop: number | undefined //是否开发 0:不开发 1:开发
  productNumber: string | undefined //产品型号
  quoteNo: string | undefined //报价单号
  quoteNumber: string | undefined //报价单序号
  price: string | undefined //未税单价
  taxUnitPrice: string | undefined //含税单价
  taxPrice: string | undefined //含税总价
  productDeliveryDay: string | undefined //产品交期
  abrogationReason: string | undefined //终止原因
  finishDate: string | undefined //完成时间
  status: number //状态0:待分配 1:评估中 2:开发中 3:报价中 4:已完成 5:已终止
  isDeleted: number | undefined //是否删除 0：未删除 1：已删除
  createdBy: string | undefined //创建人
  createdTime: string | undefined //创建时间
  updatedBy: string | undefined //更新人
  updatedTime: string | undefined //更新时间
  demandDesc: string | undefined //需求描述
  isPositive: number | undefined //是否转正式型号0：否 1：是
  officialProductNumber: string | undefined //正式型号
  fileInfoList: Data[] | undefined
  proposalType: number | undefined //1：创新型 ，2：拓宽型 ， 3：延伸型
  expectedTime: number | undefined //期望时间
  estimatedTime: string | undefined //预计完成时间
  assessDwellTime: string | undefined //评估状态停留时间
  isOvertime: number | undefined //0：未超时，1：已超时
  fileIdList: string[] | undefined
  createStatus?: number //报价单状态
  assigneeDate?: string //分配时间
  constructor() {
    this.status = 0
  }
}
//分页查询类
export class RegProposal extends ReqPage {
  data: Proposal
  constructor() {
    super()
    this.data = new Proposal()
  }
}
export interface ResProposalClass extends ReqPage {
  data: Proposal[]
}
export interface AddProposal {
  companyName: string | undefined //公司名称
  contact: string | undefined //联系人
  demandProduct: string | undefined //需求产品名称
  deviceName: string | undefined //应用设备名称
  demandCount: string | undefined //预计需求数量
  useEnv: string | undefined //使用环境
  demandDate: string | undefined //需求时间
  demandDesc: string | undefined //需求描述
  fileIdList: string[] | undefined //文件集合
}
export interface ResProposalDetail {
  proposalVO: Proposal
  copFileInfoVOList: Data[]
}
export interface ProposalDetail {
  proposalVO: Demand
  demandInfoVO: Proposal
  copFileInfoVOList: Data[]
}

/**
 * @description:  提案日志返回
 * @param { string } demandNo
 * @param { string } title
 * @param { string } operator
 * @param { string } operatorCode
 * @param { string } description
 * @param { string } operatorDate
 */
export interface DemandLogInfo {
  demandNo: string
  title: string
  operator: string
  operatorCode: string
  description: string
  operatorDate: string
}
