import Data from '@/model/common/Data'
import { $axios } from '../config/axios'
class HomeApi {
  // 获取竞品信息竞品与填报信息条形图
  GetIndexProduct(): Promise<Data> {
    return $axios.post('/api/bcic/index/product', {})
  }
  // 获取客户统计饼状图
  GetIndexCustomer(): Promise<Data> {
    return $axios.post('/api/bcic/index/customer', {})
  }
  // 获取情报中心首页需求信息折线图
  GetIndexDemand(data: unknown): Promise<Data> {
    return $axios.post('/api/bcic/index/demand', data)
  }
  // 获取首页需求、成果、竞品、客户数据统计
  GetIndexCount(): Promise<Data> {
    return $axios.post('/api/bcic/index/count', {})
  }
}
let homeApi
export default (() => {
  if (homeApi) return homeApi
  homeApi = new HomeApi()
  return homeApi
})()
